<template>
	<BaseLoading v-if="edit.isLoading" is-full-page />
	<div v-else class="container-fluid">
		<div class="page-header">
			<CRow>
				<CCol md="12">
					<h2 class="typo-h4 d-flex align-items-center">
						{{ edit.data.nameEn }}
						<CBadge
							:color="edit.data.periodStatus.color"
							class="badge-status"
							data-test-id="badge-order-status"
						>
							{{ edit.data.periodStatus.title }}
						</CBadge>
					</h2>
				</CCol>
			</CRow>
			<CRow class="summary">
				<CCol md="12">
					<p class="typo-body-2 m-0 p-0">
						Last updated: <span class="color-black-45">{{ edit.data.updatedAt }}</span>
					</p>
					<p class="typo-body-2 m-0">
						Created by: <span class="color-black-45">{{ edit.data.createdAt }}</span>
					</p>
				</CCol>
			</CRow>
		</div>
		<TagFilterForm
			:is-submitting="edit.isUpdating"
			is-edit
			:default-data="edit.data"
			:is-slug-duplicate="edit.fieldErrors.slug"
			:is-available-product-error="edit.fieldErrors.availableProduct"
			@onSubmit="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TagFilterForm from '@/components/TagFilterForm.vue';

export default {
	name: 'TagFilterCreate',

	components: {
		TagFilterForm,
	},

	data() {
		return {
			id: null,
			defaultData: null,
			// Use this loading to wait data from store
			isLoading: true,
		};
	},

	computed: {
		...mapState('tagFilters', {
			edit: 'edit',
		}),
	},

	async created() {
		this.id = this.$route?.params?.id ? Number(this.$route.params.id) : null;

		try {
			if (this.id) {
				await this.getTagFilter(this.id);

				this.defaultData = this.edit.data;
			}
		} finally {
			this.isLoading = false;
		}
	},

	methods: {
		...mapActions({
			getTagFilter: 'tagFilters/getTagFilter',
			updateTagFilter: 'tagFilters/updateTagFilter',
		}),

		async handleSubmit(formData) {
			await this.updateTagFilter({ id: this.id, params: formData });
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-header {
		margin: 0 rem(-28);
		padding: 0 rem(28);
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
	}
</style>
